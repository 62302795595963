import { lazy } from 'react';

// ----------------------------------------------------------------------

const BankApplicationPage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "BankApplicationPage" */
      '@/pages/dorim-finance/BankApplication/BankApplicationPage'
    ),
);

export default BankApplicationPage;
