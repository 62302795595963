import { Suspense } from 'react';
import { Trans } from 'react-i18next';

import { ProgressBar } from '@/components/ProgressBar';
import { AuthGuard } from '@/guards/AuthGuard.tsx';
import { MainLayout } from '@/layouts/MainLayout';
import { BreadcrumbLink, DorimFinancePaths, type RouteObjectWithBreadcrumbs } from '@/shared/lib/react-router';

import BankApplicationPage from './BankApplication/BankApplicationPage.async';
import DorimFinancePage from './DorimFinancePage.async';

export const DorimFinanceRoute: RouteObjectWithBreadcrumbs = {
  path: DorimFinancePaths.ROOT_PATH,
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  handle: {
    breadcrumb: {
      element: (
        <BreadcrumbLink to={DorimFinancePaths.ROOT_PATH}>
          <Trans ns="breadcrumbs" i18nKey="dorim-finance.root" />
        </BreadcrumbLink>
      ),
    },
  },
  children: [
    {
      index: true,
      element: (
        <Suspense fallback={<ProgressBar />}>
          <DorimFinancePage />
        </Suspense>
      ),
    },

    {
      path: DorimFinancePaths.BANK_APPLICATION_PATH,
      handle: {
        breadcrumb: {
          element: (
            <BreadcrumbLink to={DorimFinancePaths.BANK_APPLICATION_PATH}>
              <Trans ns="breadcrumbs" i18nKey="dorim-finance.registration" />
            </BreadcrumbLink>
          ),
        },
      },
      element: (
        <Suspense fallback={<ProgressBar />}>
          <BankApplicationPage />
        </Suspense>
      ),
    },
  ],
};
