import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSearchParam } from 'react-use';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { css, FormControl, Paper, Stack, styled, Typography } from '@mui/material';

import { useGlobalErrorStore } from '@/components/dialogs';
import {
  CustomFormLabel,
  Form,
  RHFIntegerTextField,
  RHFMaskedPhoneTextField,
  RHFTextField,
} from '@/components/form-controls';
import { isSafeNumeric } from '@/shared/helpers/strings.helpers';
import { ReferralPaths } from '@/shared/lib/react-router';
import { Yup } from '@/shared/lib/yup';

import { useSendRegistrationRequest } from './hooks';

const StyledWideFormRoot = styled(Paper)`
  display: flex;
  flex-flow: column;
  align-items: stretch;
  justify-content: stretch;
  flex: 1 1 auto;

  position: relative;

  border-radius: 16px;
  padding: 32px 40px 40px;

  ${({ theme }) => css`
    ${theme.breakpoints.down('tablet')} {
      border-radius: 0;
      padding: 40px 16px 0 16px;
      box-shadow: none;
    }
  `}
`;

const StyledFormRoot = styled(StyledWideFormRoot)`
  max-width: 408px;
`;

const StyledFormTitle = styled(Typography)`
  color: #1e2f96;
`;

// ----------------------------------------------------------------------

type FormValues = { firstName: string; lastName: string; phone: string; tin: string; referralCode: string };
const TIN_MAX_LENGTH = 9;

const formSchema = Yup.object().shape({
  firstName: Yup.string().required('Обязательное поле'),
  lastName: Yup.string().required('Обязательное поле'),
  phone: Yup.string().isPossiblePhoneNumber().required('Обязательное поле'),
  tin: Yup.string().trim().required('Обязательное поле').length(TIN_MAX_LENGTH, 'ИНН должен состоять из 9 символов'),
  referralCode: Yup.string(),
});

export const ReferralForm = () => {
  const { t } = useTranslation('referral-page');

  const navigate = useNavigate();

  const referralCodeFromQuery = useSearchParam('referralCode') || '';

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    resolver: yupResolver(formSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      phone: '',
      tin: '',
      referralCode: referralCodeFromQuery,
    },
  });

  const {
    formState: { isSubmitting },
    handleSubmit: wrapHandleSubmit,
  } = methods;

  const { setGlobalError } = useGlobalErrorStore();

  const { mutateAsync: sendRegistrationRequest } = useSendRegistrationRequest();

  const handleSubmit = async (formData: FormValues) => {
    try {
      const { firstName, lastName, phone, tin, referralCode } = formData;

      await sendRegistrationRequest({
        payload: {
          first_name: firstName,
          last_name: lastName,
          phone,
          tin,
          referral_code: referralCode,
        },
      });
      navigate(ReferralPaths.REFERRAL_REQUEST_COMPLETED_PATH);
    } catch (error) {
      setGlobalError(error);
    }
  };

  return (
    <StyledFormRoot>
      <Stack spacing={5}>
        <Stack spacing={2}>
          <StyledFormTitle variant="Title/Bold/large">{t('form.title')}</StyledFormTitle>
          <Typography variant="Body/medium" color="neutral.700">
            {t('form.description')}
          </Typography>
        </Stack>

        <FormProvider {...methods}>
          <Form onSubmit={wrapHandleSubmit(handleSubmit)} isDisabled={isSubmitting}>
            <Stack spacing={3} mb={3}>
              <FormControl fullWidth>
                <CustomFormLabel isRequired htmlFor="firstName">
                  {t('form.labels.first-name')}
                </CustomFormLabel>
                <RHFTextField
                  fullWidth
                  name="firstName"
                  id="firstName"
                  autoComplete="firstName"
                  variant="filled"
                  inputProps={{ 'data-testid': 'referral-first-name-input' }}
                />
              </FormControl>
              <FormControl fullWidth>
                <CustomFormLabel isRequired htmlFor="lastName">
                  {t('form.labels.last-name')}
                </CustomFormLabel>
                <RHFTextField
                  fullWidth
                  name="lastName"
                  id="lastName"
                  autoComplete="lastName"
                  variant="filled"
                  inputProps={{ 'data-testid': 'referral-last-name-input' }}
                />
              </FormControl>
              <Stack direction="row" spacing={2}>
                <FormControl fullWidth>
                  <CustomFormLabel isRequired htmlFor="phone">
                    {t('form.labels.phone')}
                  </CustomFormLabel>
                  <RHFMaskedPhoneTextField
                    fullWidth
                    name="phone"
                    id="phone"
                    autoComplete="phone"
                    variant="filled"
                    inputProps={{ 'data-testid': 'referral-phone-input' }}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <CustomFormLabel isRequired htmlFor="tin">
                    {t('form.labels.tin')}
                  </CustomFormLabel>
                  <RHFIntegerTextField
                    isAllowed={({ value }) => isSafeNumeric(value) && value.length <= TIN_MAX_LENGTH}
                    fullWidth
                    name="tin"
                    id="tin"
                    autoComplete="tin"
                    variant="filled"
                    inputProps={{ 'data-testid': 'referral-tin-input' }}
                  />
                </FormControl>
              </Stack>
              {!referralCodeFromQuery && (
                <FormControl fullWidth>
                  <CustomFormLabel htmlFor="referralCode">{t('form.labels.referral-code')}</CustomFormLabel>
                  <RHFTextField
                    fullWidth
                    name="referralCode"
                    id="referralCode"
                    variant="filled"
                    inputProps={{ 'data-testid': 'referral-referral-code-input' }}
                  />
                </FormControl>
              )}
            </Stack>

            <LoadingButton
              fullWidth
              loading={isSubmitting}
              type="submit"
              variant="contained"
              color="secondary"
              data-testid="signin-btn"
            >
              {t('form.actions.send')}
            </LoadingButton>
          </Form>
        </FormProvider>
      </Stack>
    </StyledFormRoot>
  );
};
