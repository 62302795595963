import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Card, Grid, Stack, styled, Typography } from '@mui/material';

import { RoundedButton } from '@/components/buttons';
import { DorimFinancePaths } from '@/shared/lib/react-router';

import dorimFinanceBannerBgPNG from '../assets/dorim_finance_banner_bg.png';

const StyledRoot = styled(Card)({
  width: '100%',
  flexShrink: 0,
  background: `url(${dorimFinanceBannerBgPNG}) no-repeat center/140% 100%`,
}) as typeof Card;

export const DorimFinanceToolbar = () => {
  const { t } = useTranslation('dorim-finance-page');
  const navigate = useNavigate();

  return (
    <StyledRoot component={Stack} variant="outlined" sx={{ width: '100%' }}>
      <Grid container sx={{ minHeight: '72px', flexShrink: 0, p: 2, alignItems: 'center' }}>
        <Grid item xs={8} textAlign="end">
          <Typography variant="Title/large">
            <Trans
              ns="dorim-finance-page"
              i18nKey="toolbars.dorim-finance-toolbar.description"
              components={{ b: <b /> }}
            />
          </Typography>
        </Grid>
        <Grid item xs={4} textAlign="end">
          <RoundedButton variant="outlined" onClick={() => navigate(DorimFinancePaths.ROOT_PATH)}>
            {t('toolbars.dorim-finance-toolbar.actions.apply')}
          </RoundedButton>
        </Grid>
      </Grid>
    </StyledRoot>
  );
};
