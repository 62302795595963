import { joinPath } from '..';

const ROOT_PATH = '/dorim-finance';

const BANK_APPLICATION_PATH = joinPath(ROOT_PATH, 'bank-application');

export const DorimFinancePaths = {
  ROOT_PATH,
  BANK_APPLICATION_PATH,
};
