import { useTranslation } from 'react-i18next';
import { ManageSearch as ManageSearchIcon, Settings as SettingsIcon } from '@mui/icons-material';

import { getDorimEnvironment } from '@/shared/helpers/common.helpers';
import {
  DorimFinancePaths,
  DorimPromoPaths,
  OrdersLinks,
  ProductSelectionPaths,
  RequirementsCalculationPaths,
  SettingsPaths,
} from '@/shared/lib/react-router';

import { ReactComponent as CalculateSVG } from './assets/icons/calculate.svg';
import { ReactComponent as CashbackSVG } from './assets/icons/cashback.svg';
import { ReactComponent as DorimFinanceSVG } from './assets/icons/dorim_finance.svg';
import { ReactComponent as OrderApproveSVG } from './assets/icons/order_approve.svg';
import { NavbarBase } from './NavbarBase';
import { NavbarItem } from './NavbarItem';

export const Navbar = () => {
  const { t } = useTranslation('navbar');
  const { isProd } = getDorimEnvironment();

  return (
    <NavbarBase>
      {!isProd && (
        <NavbarItem
          iconComponent={CalculateSVG}
          to={RequirementsCalculationPaths.ROOT_PATH}
          title={t('requirements-calculation')}
        />
      )}
      <NavbarItem
        iconComponent={ManageSearchIcon}
        to={ProductSelectionPaths.ROOT_PATH}
        title={t('product-selection')}
      />
      <NavbarItem iconComponent={OrderApproveSVG} to={OrdersLinks.root} title={t('orders')} />
      <NavbarItem iconComponent={DorimFinanceSVG} to={DorimFinancePaths.ROOT_PATH} title={t('dorim-finance')} />
      <NavbarItem iconComponent={CashbackSVG} to={DorimPromoPaths.ROOT_PATH} title={t('dorim-promo')} />
      <NavbarItem
        iconComponent={SettingsIcon}
        to={SettingsPaths.ROOT}
        title={t('settings.title')}
        submenu={[
          {
            to: SettingsPaths.DISTRIBUTORS,
            title: t('settings.submenu.distributors'),
          },
          {
            to: SettingsPaths.EXPIRY_DATES,
            title: t('settings.submenu.expiry-dates'),
          },
          {
            to: SettingsPaths.USERS,
            title: t('settings.submenu.users'),
          },
          {
            to: SettingsPaths.PHARMACIES,
            title: t('settings.submenu.pharmacies'),
          },
        ]}
      />
    </NavbarBase>
  );
};
