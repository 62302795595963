import { lazy } from 'react';

// ----------------------------------------------------------------------

const DorimFinancePage = lazy(
  () =>
    import(
      /* webpackPreload: true */
      /* webpackChunkName: "DorimFinancePage" */
      '@/pages/dorim-finance/DorimFinancePage'
    ),
);

export default DorimFinancePage;
