import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material';
import { bindMenu, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';

import { ActionButton } from '@/components/buttons';
import { ArrowDropDownSVG, DownloadSVG } from '@/shared/assets/icons-2.0';

export const DownloadTemplatesButton = () => {
  const popupState = usePopupState({ variant: 'popover', popupId: 'DownloadTemplatesMenu' });
  const { t } = useTranslation('specifications-detail-page');

  return (
    <Fragment>
      <ActionButton
        startIcon={<SvgIcon component={DownloadSVG} inheritViewBox fontSize="small" />}
        endIcon={<SvgIcon component={ArrowDropDownSVG} inheritViewBox fontSize="small" />}
        {...bindTrigger(popupState)}
      >
        {t('actions.download-template-button.title')}
      </ActionButton>

      <Menu
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: -4, horizontal: 'center' }}
        slotProps={{
          paper: {
            sx: { width: 233 },
          },
        }}
        {...bindMenu(popupState)}
      >
        <MenuItem
          component={Link}
          href={import.meta.env.VITE_APP_SPECIFICATION_WORK_COMPLETION_CERT_URL}
          onClick={popupState.close}
        >
          <ListItemText primaryTypographyProps={{ variant: 'Body/medium' }}>
            {t('actions.download-template-button.menu.work-completion-act')}
          </ListItemText>
        </MenuItem>
        <MenuItem component={Link} href={import.meta.env.VITE_APP_SPECIFICATION_INVOICE_URL} onClick={popupState.close}>
          <ListItemText primaryTypographyProps={{ variant: 'Body/medium' }}>
            {t('actions.download-template-button.menu.specification-invoice')}
          </ListItemText>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};
